
import lang from '@/mixins/lang.js'
export default {
  name: 'SectionFAQ',
  mixins: [lang],
  props: {
    section: {
      type: Object,
      default() {},
    },
  },
  data() {
    return {
      accordionIndex: null,
      displayingFaqCount: 5,
    }
  },
  methods: {
    accordion(index) {
      this.accordionIndex === index
        ? (this.accordionIndex = null)
        : (this.accordionIndex = index)
    },
    showMoreFaq() {
      this.displayingFaqCount += 5
    },
  },
}
