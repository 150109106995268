export default {

  head() {
    if (this.seo && this.seo !== null && this.seo !== undefined) {
      return {
        title: this.seo.title !== null ? this.seo.title : 'Golden Horn',
        htmlAttrs: {
          lang: this.activeLang && this.activeLang.length > 0 ?
            this.activeLang : this.browserLang === 'tr-TR' ?
            'tr' : 'en'
        },
        meta: [{
            hid: 'description',
            name: 'description',
            content: !!this.seo.description,
          },
          // OG Metas
          {
            hid: 'og:title',
            property: 'og:title',
            content: !!this.seo.title ? this.seo.title : 'Golden Horn',
          },
          {
            hid: 'og:description',
            property: 'og:description',
            content: !!this.seo.description ? this.seo.description : 'Golden Horn',
          },
          {
            hid: 'og:type',
            property: 'og:type',
            content: 'website',
          },
          {
            hid: 'og:site_name',
            name: 'site_name',
            property: 'og:site_name',
            content: 'Golden Horn',
          },
          {
            hid: 'og:author',
            name: 'author',
            property: 'og:author',
            content: 'efabrika',
          },
          {
            hid: 'og:locale',
            property: 'og:locale',
            content: 'tr_TR',
          },
          {
            hid: 'og:image',
            property: 'og:image',
            content: !!this.seo.shareImage && !!this.seo.shareImage.url ? process.env.CDN_URL + this.seo.shareImage.url : './golden-horn_og-image.jpg',
          },
          {
            hid: 'og:url',
            property: 'og:url',
            content: !!this.seo.canonical ? this.seo.canonical : process.env.CDN_URL + this.$route.path,
          },
          {
            hid: 'og:keywords',
            name: 'keywords',
            property: 'og:keywords',
            content: !!this.seo.keywords ? this.seo.keywords : 'Golden Horn' || 'Golden Horn',
          },
          {
            hid: 'twitter:image',
            name: 'twitter:image',
            property: 'twitter:image',
            content: !!this.seo.shareImage && !!this.seo.shareImage.url ? process.env.CDN_URL + this.seo.shareImage.url : './golden-horn_og-image.jpg',
          },
          {
            hid: 'twitter:site',
            name: 'site',
            property: 'twitter:site',
            content: '@goldenhorn',
          },
          {
            hid: 'twitter:card',
            name: 'twitter:card',
            property: 'twitter:card',
            content: 'summary_large_image',
          },
          {
            hid: 'twitter:creator',
            name: 'twitter:creator',
            property: 'twitter:creator',
            content: '@goldenhorn',
          },
          {
            hid: 'twitter:title',
            name: 'twitter:title',
            property: 'twitter:title',
            content: !!this.seo.title ? this.seo.title : 'Golden Horn',
          },
          {
            hid: 'twitter:description',
            name: 'twitter:description',
            property: 'twitter:description',
            content: !!this.seo.description ? this.seo.description : 'Golden Horn',
          },
          {
            hid: 'twitter:image:alt',
            name: 'twitter:image:alt',
            property: 'twitter:image:alt',
            content: !!this.seo.shareImage && !!this.seo.shareImage.alternativeText ? this.seo.shareImage.alternativeText : 'Golden horn Og Image',
          },
        ],
      };
    } else {
      return {
        htmlAttrs: {
          lang: this.activeLang && this.activeLang.length > 0 ?
            this.activeLang : this.browserLang === 'tr-TR' ?
            'tr' : 'en'

        },
        title: 'Golden Horn'
      }
    }
  }
};
