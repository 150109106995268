
import lang from '@/mixins/lang.js'
import seo from '@/mixins/seo.js'

export default {
  layout: 'home',
  mixins: [lang, seo],
  data() {
    return {
      pageData: {},
      seo: {
        title: '',
        description: '',
        keywords: '',
        shareImage: '',
        canonical: '',
        content: '',
      },
    }
  },
  async fetch() {
    await this.browserLang
    const { data } = await this.$axios.get(
      `/home?_locale=${
        this.activeLang && this.activeLang.length > 0
          ? this.activeLang
          : this.browserLang === 'tr-TR'
          ? 'tr'
          : 'en'
      }`
    )
    this.pageData = data
    this.seo = data.SEO
  },
}
