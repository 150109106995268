import { render, staticRenderFns } from "./index.vue?vue&type=template&id=335ffcc6&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {HeroPromo: require('/usr/app/components/hero/Promo.vue').default,SectionSteppedCards: require('/usr/app/components/section/SteppedCards.vue').default,SectionGeneric: require('/usr/app/components/section/Generic.vue').default,SectionVideo: require('/usr/app/components/section/Video.vue').default,SectionTestimonial: require('/usr/app/components/section/Testimonial.vue').default,SectionFAQ: require('/usr/app/components/section/FAQ.vue').default})
