
import lang from '@/mixins/lang.js'

export default {
  name: 'HeroPromo',
  mixins: [lang],
  props: {
    hero: {
      type: Object,
      default() {},
    },
  },
  data() {
    return {
      headerLogo: {},
      cdnUrl: '',
    }
  },
  async fetch() {
    this.cdnUrl = await process.env.CDN_URL
    await this.browserLang
    const { data } = await this.$axios.get(
      `/site-settings?_locale=${
        this.activeLang && this.activeLang.length > 0
          ? this.activeLang
          : (await this.browserLang) === 'tr-TR'
          ? 'tr'
          : 'en'
      }`
    )
    this.headerLogo = await data.headerSettings.logo
  },
}
