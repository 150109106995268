
export default {
  name: 'SectionTestimonial',

  props: {
    section: {
      type: Object,
      default() {},
    },
  },
  data() {
    return {
      cdnUrl: '',
      currentSlide: null,
      showPoster: true,
      embed: '',
      asNavForMain: [],
      asNavForThumbnail: [],
      main: {
        dots: false,
        fade: true,
        navButtons: false,
        infinite: this.section.videos.length >= 4 ? true : false,
        changeDelay: true,
      },
      thumbnail: {
        slidesToShow: 2.5,
        autoplay: false,
        centerMode: false,
        infinite: false,
        navButtons: false,
        changeDelay: true,
        dots: true,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              navButtons: false,
              slidesToShow: 3.5,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              navButtons: true,
              dots: false,
              slidesToShow: 4,
            },
          },
        ],
      },
    }
  },
  created() {
    this.cdnUrl = process.env.CDN_URL
  },
  mounted() {
    this.asNavForMain.push(this.$refs.thumbnails)
    this.asNavForThumbnail.push(this.$refs.main)
  },
  methods: {
    hide(event) {
      this.showPoster = false
    },
    getCurrentSlide(event) {
      let currentElement = document.querySelector(
        `.agile__slides--regular .slide--${event.currentSlide} iframe` ||
          `.agile__slides--regular .slide--${event.currentSlide} video source`
      )
      currentElement.src = currentElement.src
      this.showPoster = true
    },
    reload(index) {
      this.$refs.thumbnails.goTo(index)
      this.$refs.main.goTo(index)
    },
  },
}
