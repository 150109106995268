import { render, staticRenderFns } from "./Video.vue?vue&type=template&id=78e5456a&"
import script from "./Video.vue?vue&type=script&lang=js&"
export * from "./Video.vue?vue&type=script&lang=js&"
import style0 from "./Video.vue?vue&type=style&index=0&id=78e5456a&prod&lang=scss&"
import style1 from "./Video.vue?vue&type=style&index=1&id=78e5456a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/usr/app/components/icon/Icon.vue').default,Picture: require('/usr/app/components/picture/Picture.vue').default})
