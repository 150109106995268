
import lang from '~/mixins/lang.js'

export default {
  name: 'SectionGeneric',
  mixins: [lang],
  props: {
    section: {
      type: Object,
      default() {},
    },
  },
}
