
export default {
  name: 'CardTestimonial',
  props: {
    card: {
      type: Object,
      default() {},
    },
  },
}
