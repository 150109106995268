
export default {
  name: 'SectionTestimonial',

  props: {
    section: {
      type: Object,
      default() {},
    },
  },
  data() {
    return {
      options: {
        navButtons: false,
        infinite: false,
        dots: this.section.testimonials.length < 2 ? false : true,
        slidesToShow: 1,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: this.section.testimonials.length > 2 ? 2.15 : 2,
              dots: this.section.testimonials.length < 3 ? false : true,
              unagile: this.section.testimonials.length < 3 ? true : false,
            },
          },
        ],
      },
    }
  },
}
