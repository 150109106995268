import { render, staticRenderFns } from "./FAQ.vue?vue&type=template&id=a0a95d0c&"
import script from "./FAQ.vue?vue&type=script&lang=js&"
export * from "./FAQ.vue?vue&type=script&lang=js&"
import style0 from "./FAQ.vue?vue&type=style&index=0&id=a0a95d0c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Icon: require('/usr/app/components/icon/Icon.vue').default,Markdown: require('/usr/app/components/markdown/Markdown.vue').default,Button: require('/usr/app/components/button/Button.vue').default})
